import bandLogo from './assets/images/whiteOutlineLogo3.png';
import vLogo from './assets/images/vLogo.png';
import aliveArt from './assets/images/alive.png';
import band1 from './assets/images/band1.jpg';
import band2 from './assets/images/band2.jpg';
import band3 from './assets/images/band3.jpg';
import band4 from './assets/images/band4.jpg';
import band5 from './assets/images/band5.jpg';
import band6 from './assets/images/band6.jpg';
import { IconContext } from 'react-icons';
import { FaSpotify, FaYoutube, FaInstagram } from 'react-icons/fa';
import { SiApplemusic } from 'react-icons/si';
import './App.css';

function App() {

  return (
    <div className='app-container'>

      {/* Titles */}
      <div>
        <div className='row'>
          <div className='col'>
            <img src={bandLogo} alt='Band Name' className='name-header' />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='text-white hor-font h1-text'>Alive!</div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='text-white hor-font h2-text available-now'>Available Now</div>
          </div>
        </div>
      </div>
      {/* End Titles */}

      {/* Streaming Icons */}
      <div>
        <div className='row icon-row jc-c'>
          <div className='d-flex icon-spacing'>
            <a href='https://open.spotify.com/artist/1uszVhPZoMGz4IlzTcXR02?si=OPSPEHjTSmWZAlxO66AK7Q' target='_blank' rel='noreferrer'>
              <div className='text-white'>
                <IconContext.Provider value={{ color: 'white', size: '50px' }}>
                  <div>
                    <FaSpotify />
                  </div>
                </IconContext.Provider>
              </div>
            </a>
          </div>

          <div className='d-flex icon-spacing'>
            <a href='https://music.apple.com/us/artist/viridescent/1606846203' target='_blank' rel='noreferrer'>
              <div className='text-white'>
                <IconContext.Provider value={{ color: 'white', size: '50px' }}>
                  <div>
                    <SiApplemusic />
                  </div>
                </IconContext.Provider>
              </div>
            </a>
          </div>

          <div className='d-flex icon-spacing'>
            <a href='https://www.youtube.com/channel/UC0CKZFv9_QaeOi6aqUPT_Ag' target='_blank' rel='noreferrer'>
              <div className='text-white'>
                <IconContext.Provider value={{ color: 'white', size: '50px' }}>
                  <div>
                    <FaYoutube />
                  </div>
                </IconContext.Provider>
              </div>
            </a>
          </div>

          <div className='d-flex icon-spacing'>
            <a href='https://www.instagram.com/viridescent_tx/?utm_source=ig_embed&amp;utm_campaign=loading' target='_blank' rel='noreferrer'>
              <div className='text-white'>
                <IconContext.Provider value={{ color: 'white', size: '50px' }}>
                  <div>
                    <FaInstagram />
                  </div>
                </IconContext.Provider>
              </div>
            </a>
          </div>

        </div>
      </div>
      {/* End Streaming Icons */}

      {/* Start Content */}
      <div className='my-container'>
        <div className='row big-row-spacing'>

          <div className='art-container col col-xl-6'>
            <a href='https://open.spotify.com/track/4X1qpMZLoTUMho5IUYSYVk?si=3231be04b31e404e' target='_blank' rel='noreferrer'>
              <img src={aliveArt} alt='Alive' className='album-art' />
            </a>
          </div>

          <div className='video-container col col-xl-6'>
            <iframe
              id='embers-mv'
              className='spotlight-video'
              src="https://www.youtube.com/embed/MLR0NAdHAOQ?si=Qn9N9LLobmphAfGx"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen>
            </iframe>
          </div>
        </div>

        {/* Shows */}
        <div className='show-title-container'>
          <h2 className='shows-title hor-font'>Shows</h2>
        </div>
        <div className='row small-row-spacing'>
          <div className='text-container col'>
            <button className="bit-widget-initializer"
              data-artist-name="Viridescent"
              data-display-local-dates="true"
              data-display-past-dates="true"
              data-auto-style="true"
              data-text-color="#FFFFFF"
              data-link-color="#00b4b3"
              data-background-color="rgba(0,0,0,0)"
              data-display-limit="15"
              data-display-start-time="false"
              data-link-text-color="#FFFFFF"
              data-display-lineup="false"
              data-display-play-my-city="false"
              data-separator-color="rgba(124,124,124,0.25)">
            </button>

          </div>
        </div>
        {/* End Shows */}



        {/* About */}
        <div className='row big-row-spacing jc-c'>
          <div className='photo-container col-12 col-lg-6'>
            <img src={band6} alt='band' className='about-photo' />
          </div>

          <div className='photo-container col-12 col-lg-6'>
            <img src={band5} alt='band' className='about-photo' />
          </div>

          <div className='photo-container col-12 col-lg-6'>
            <img src={band1} alt='band' className='about-photo' />
          </div>

          <div className='photo-container col-12 col-lg-6'>
            <img src={band2} alt='band' className='about-photo' />
          </div>

          <div className='photo-container col-12 col-lg-6'>
            <img src={band3} alt='band' className='about-photo' />
          </div>

          <div className='photo-container col-12 col-lg-6'>
            <img src={band4} alt='band' className='about-photo' />
          </div>
        </div>

        <div className='row small-row-spacing'>
          <div className='text-container col'>
            <p className='about-text'>
              Viridescent is:
              <br />
              Alexander Templin - Guitars
              <br />
              Mira Divina - Vocals
              <br />
              Dylan Ory - Vocals & Production
              <br />
              Jake Stevens - Drums
            </p>
            <p className='about-text'>
              Dallas, TX. Reach out to us on:
              <br />
              Youtube: @viridescenttx
              <br />
              Instagram: @viridescent_tx
              <br />
              viridescentofficial@gmail.com
            </p>
          </div>
        </div>
        {/* End About */}

        {/* Footer Logo */}
        <div className='row big-row-spacing'>
          <div className='col'>
            <img src={vLogo} alt='Viridescent Logo' className='footer-logo' />
          </div>
        </div>
      </div>
      {/* End Content */}

      <div className="footer">
        <div className="footer-text">Viridescent 2025 | viridescentofficial@gmail.com</div>
      </div>

    </div>
  );
}

export default App;
